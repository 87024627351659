import { useContext, useMemo } from 'react';

import { useGetCurrencyRateQuery } from 'hooks/useGetCurrencyRate';
import { getPriceStr } from 'utils';
import { CurrencyContext } from 'context/CurrencyContext';
import Placeholder from '@guestyci/foundation/Placeholder';

const PriceConverter = ({ currency, amount }) => {
  const { selectedCurrency } = useContext(CurrencyContext);
  const { data, status, isLoading, isSuccess } = useGetCurrencyRateQuery({ from: currency, to: selectedCurrency });
  const calculatedAmount = isSuccess ? data?.rate * amount : amount;
  const price = useMemo(() => getPriceStr(Math.round(calculatedAmount), selectedCurrency), [calculatedAmount, selectedCurrency]);

  if (status === 'loading' || isLoading) {
    <Placeholder className="cell-placeholder height-10 w-fill" />
  }

  return <span>{price}</span>;
};

export default PriceConverter;
